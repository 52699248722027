import "normalize.css";
import "./sass/app.scss";
import $ from "jquery";
const Masonry = require("../node_modules/masonry-layout/dist/masonry.pkgd.js");
import CountdownTimer from "./js/countdown";

CountdownTimer("#timer");
CountdownTimer("#timer2");
CountdownTimer("#timer3");

window.addEventListener("load", () => {
  let msnry = new Masonry("#grid-ref-presale", {
    columnWidth: ".grid-sizer",
    itemSelector: ".grid-presale-item",
    percentPosition: true,
  });

  let msnry2 = new Masonry("#grid-ref", {
    columnWidth: ".grid-sizer--50",
    itemSelector: ".grid-presale-item",
    percentPosition: true,
  });
});
