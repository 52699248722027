let Countdown = require("countdown-js");

function CountdownTimer(id) {
  const timerDiv = document.querySelector(id);

  if (timerDiv === null) return;

  const days = timerDiv.querySelector(".days");
  const hours = timerDiv.querySelector(".hours");
  const minutes = timerDiv.querySelector(".minutes");
  const seconds = timerDiv.querySelector(".seconds");

  // setup end datetime for timer
  let end = new Date(2023, 11 - 1, 30, 23, 59);

  let timer = Countdown.timer(
    end,
    function (timeLeft) {
      if (timeLeft.days < 10) {
        days.textContent = "0" + timeLeft.days;
      } else {
        days.textContent = timeLeft.days;
      }

      if (timeLeft.hours < 10) {
        hours.textContent = "0" + timeLeft.hours;
      } else {
        hours.textContent = timeLeft.hours;
      }

      if (timeLeft.minutes < 10) {
        minutes.textContent = "0" + timeLeft.minutes;
      } else {
        minutes.textContent = timeLeft.minutes;
      }

      if (timeLeft.seconds < 10) {
        seconds.textContent = "0" + timeLeft.seconds;
      } else {
        seconds.textContent = timeLeft.seconds;
      }
    },
    function () {
      window.location.href = "https://thewolfelite.pl/zapisy-zamkniete.html";
    }
  );
}

export default CountdownTimer;
